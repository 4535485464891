import React from 'react';
import dynamic from 'adapter/next/dynamic';
import { useInView } from 'react-intersection-observer';
import { Box, type BoxProps } from '@konsus/superside-kit';

const SocialLinksContent = dynamic(
  () => import(/* webpackChunkName: "Social_Links_Content" */ './SocialLinksContent')
);

export interface SocialLinksProps {
  size?: string;
  showImages?: boolean;
  background?: string;
  website?: 'superside' | 'careers';
}

export const SocialLinks: React.FunctionComponent<BoxProps & SocialLinksProps> = (props) => {
  const {
    size,
    showImages = true,
    background = 'primary',
    website = 'superside',
    gap,
    color,
    ...rest
  } = props;
  const [ref, isVisible] = useInView({ rootMargin: '100px 0px', triggerOnce: true });

  return (
    <Box justify='center' ref={ref} {...rest}>
      {isVisible ? (
        <SocialLinksContent
          size={size}
          showImages={showImages}
          website={website}
          background={background}
          gap={gap}
          color={color}
        />
      ) : null}
    </Box>
  );
};

export default SocialLinks;
